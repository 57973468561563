import * as types from "./types";
import * as userTypes from "../user/types";
import * as pageTypes from "../page/types";
import * as orderTypes from "../orderInProgress/types";
import * as sessionTypes from "../session/types";
import * as areaTypes from "../areas/types";
import * as addressTypes from "../addresses/types";
import * as paymentTypeTypes from "../paymentTypes/types";
import * as savedCardsTypes from "../savedCards/types";
import * as invoiceTypes from "../invoice/types";
import * as tipTypes from "../tips/types";
import { combineReducers } from "redux";

const genericError = () => {
  return {
    title: "Oops!",
    message: "Something went wrong. Please try again later.",
  };
}

const genericErrorWithRedirect = (redirectionUrl: string = "/") => {
  return {
    title: "Oops!",
    message: "Something went wrong. Please try again later.",
    redirectionUrl: redirectionUrl
  };
}

const authError = (action) => {
  return {
    title: "Oops!",
    message: action.error.response.data.error,
    redirectionUrl: "/",
  };
}

const errorHandler = (action) => {
  switch (action.error.response.status) {
    case 401:
      return authError(action);
    default:
      return genericError();
  }
}

const errorHandlerWithRedirect = (action) => {
  switch (action.error.response.status) {
    case 401:
      return authError(action);
    default:
      return genericErrorWithRedirect();
  }
}

const verifyUserError = (action) => {
  if (action.error.response.status == 404) {
    return null;
  } else {
    return genericError();
  }
}

const verifyPaymentError = (action) => {
  if (action.error.response.status == 404) {
    return {
      title: "Oops!",
      message: "We're unable to find the order you were looking for.",
      redirectionUrl: "/",
    };
  } else {
    return genericErrorWithRedirect();
  }
}

const cardDeletionError = () => {
  return {
    title: "Oops!",
    message: "We're unable to delete your card. Please try again later.",
  };
}

const placeOrderError = (action) => {
  switch (action.error.response.status) {
    case 401:
      return authError(action);
    default:
      return checkErrorType(action);
  }
};

const checkErrorType = (action) => {
  if (action.error.response.data?.id == "out_of_stock") {
    return {
      subTitleCode: "error.out_of_stock",
      messageCode: "error.out_of_stock",
      redirectionUrl: "/",
    };
  } else {
    return {
      titleCode: "error.oops",
      messageCode: "error.unable_to_process_order",
    };
  }
};

const myFatoorahPaymentModeError = (action) => {
  switch (action.error.response.status) {
    case 422:
      return {
        title: "Oops!",
        message: action.error.response.data.error
      };
      default:
        genericError();
  }
}

const tipPaymentError = (action) => {
  switch (action.error.response.status) {
    case 401:
      return authError(action);
    default:
      return {
        titleCode: "error.oops",
        messageCode: "error.unable_to_process_tip",
      };
  }
}

const errorReducer = (state = null, action: any) => {
  switch (action.type) {
    case types.UPDATE_ERROR:
      return action.payload;
    case types.RESET_ERROR:
      return null;
    case userTypes.UPDATE_PROFILE_FAIL:
    case userTypes.SIGN_OUT_FAIL:
    case userTypes.SEND_OTP_FAIL:
    case userTypes.RESEND_OTP_FAIL:
    case userTypes.SEND_OTP_FOR_EMAIL_RESET_FAIL:
    case userTypes.RESEND_OTP_FOR_EMAIL_RESET_FAIL:
    case pageTypes.FETCH_PROFILE_CONFIGURATIONS_FAIL:
    case pageTypes.FETCH_SIGN_IN_CONFIGURATIONS_FAIL:
    case pageTypes.FETCH_ORDER_DETAILS_CONFIGURATIONS_FAIL:
    case sessionTypes.CHANGE_DELIVERY_AREA_FAIL:
    case areaTypes.FETCH_AREAS_FAIL:
    case addressTypes.UPDATE_ADDRESS_FAIL:
    case addressTypes.DELETE_ADDRESS_FAIL:
    case addressTypes.CREATE_ADDRESS_FAIL:
    case paymentTypeTypes.BUILD_QPAY_PAYMENT_DATA_FAIL:
    case orderTypes.MARK_AS_CUSTOMER_ARRIVED_FAIL:
    case invoiceTypes.SEND_INVOICE_TO_REGISTERED_EMAIL_FAIL:
    case invoiceTypes.SEND_INVOICE_TO_NEW_EMAIL_FAIL:
      return errorHandler(action);
    case paymentTypeTypes.FETCH_MY_FATOORAH_PAYMENT_MODES_FAIL:
      return myFatoorahPaymentModeError(action);
    case userTypes.VERIFY_USER_FAIL:
      return verifyUserError(action);
    case paymentTypeTypes.VERIFY_PAYMENT_FAIL:
    case pageTypes.FETCH_PAYMENT_PROCESSING_CONFIGURATIONS_FAIL:
      return verifyPaymentError(action);
    case paymentTypeTypes.BUILD_ADYEN_PAYMENT_METHODS_FAIL:
      return genericErrorWithRedirect();
    case pageTypes.FETCH_PAST_ORDERS_CONFIGURATIONS_FAIL:
    case pageTypes.FETCH_LOYALTY_CONFIGURATIONS_FAIL:
      return errorHandlerWithRedirect(action);
    case savedCardsTypes.DELETE_SAVED_CARD_FAIL:
      return cardDeletionError();
    case orderTypes.PLACE_ORDER_FAIL:
    case paymentTypeTypes.INITIATE_STRIPE_CONNECT_TRANSACTION_FAIL:
    case paymentTypeTypes.BUILD_MASTERCARD_SESSION_FAIL:
      return placeOrderError(action);
    case tipTypes.MAKE_TIP_PAYMENT_FAIL:
      return tipPaymentError(action);

    default:
      return state;
  }
};

const reducer = errorReducer;

export default reducer;
