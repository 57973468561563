// Packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
// Components
import ModalCloseIcon from "../modalCloseIcon";
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
// Helpers, Utils etc.
import { deepCopyFunction } from "../../helpers/utils";
import { formatDecimal } from "../../helpers/itemCalculations";
import {
  translatedName,
} from "../../helpers/translations";
import { QUALITY_20, transformImageQuality } from "../../helpers/imageTransformation";

interface ICustomisationPopupProps {
  modifierItem: any;
  currency: string;
  onModifierAdd: void;
  onClose: void;
}

interface ICustomisationPopupState {
  show: boolean;
  modifierItem: any;
  cartDisabled: boolean;
  subTotal: any;
}

class CustomisationPopup extends Component<
  ICustomisationPopupProps,
  ICustomisationPopupState
> {
  constructor(props: ICustomisationPopupProps) {
    super(props);
    this.state = {
      show: true,
      modifierItem: {},
      cartDisabled: false,
      subTotal: 0,
    };
  }

  componentDidMount() {
    this.setState({
      modifierItem: this.transformedItem(this.props.modifierItem)
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.modifierItem !== this.state.modifierItem) {
      if (this.state.modifierItem) {
        this.setState({
          subTotal: this.calculateSubTotal(this.state.modifierItem),
          cartDisabled: this.checkIfCartIsDisabled(
            this.state.modifierItem
          )
        });
      }
    }
  }

  transformedItem = (item: any) => {
    item = deepCopyFunction(item);
    item.count = 1;
    item.groups = item.groups.map((group: any) => {
      let modifierCount = 0;
      group.items = group.items.map((item: any, index: number) => {
        item.selected = false;
        item.count = 0;
        if(item.is_default && !group.has_quantity_selector) {
          if (
            !group.maximum ||
            (group.maximum > 0 && modifierCount < group.maximum)
          ) {
            modifierCount++;
            item.selected = true;
            item.count++;
          }
        }
        return item;
      });
      let groupLimitReached = modifierCount >= group.maximum;
      group.items = group.items.map((item: any) => {
        item.disabled =
          group.maximum > 0 && groupLimitReached && !item.selected;
        return item;
      });
      if (group.has_quantity_selector) {
        group.type = "quantity_selector";
      } else if (group.minimum === 1 && group.maximum === 1) {
        group.type = "single_item";
      } else {
        group.type = "multiple_item";
      }
      group.minimumSelected = modifierCount >= group.minimum;
      return group;
    });
    return item;
  };

  calculateSubTotal = (item: any) => {
    let basePrice = item.unit_price;
    let total =  item.groups.reduce((itemTotal: number, group: any) => {
      let groupTotal = group.items.reduce((amount: number, groupItem: any) => {
        if (groupItem.selected) {
          return amount + (groupItem.final_price * groupItem.count);
        }
        return amount;
      }, 0);
      return itemTotal + groupTotal;
    }, basePrice);
    return total * item.count;
  };

  handleClose = () => {
    setTimeout(() => {
      this.props.onClose();
    }, 500);
    this.setState({ show: false });
  };

  onCheckBoxClick = (group: any, item: any) => {
    let modifierCount = 0;
    let groupLimitReached = false;
    if (group.type === "multiple_item") {
      group.items = group.items.map((itemInGroup: any) => {
        if (itemInGroup.id === item.id) {
          itemInGroup.selected = !itemInGroup.selected;
          itemInGroup.count = itemInGroup.selected ? 1 : 0;
        }
        if (itemInGroup.selected) {
          modifierCount++;
        }
        return itemInGroup;
      });
      groupLimitReached = group.maximum === modifierCount;
      group.items = group.items.map((itemInGroup: any) => {
        itemInGroup.disabled = group.maximum > 0 && groupLimitReached && !itemInGroup.selected;
        return itemInGroup;
      });
    } else if (group.type === "single_item") {
      modifierCount = 1;
      group.items = group.items.map((itemInGroup: any) => {
        itemInGroup.selected = itemInGroup.id === item.id;
        itemInGroup.count = itemInGroup.id === item.id ? 1 : 0;
        return itemInGroup;
      });
    } else if (group.type === "quantity_selector") {
      let groupItems = group.items.map((itemInGroup: any) => {
        itemInGroup.selected = itemInGroup.count > 0;
        itemInGroup.count = itemInGroup.count || 0;
        modifierCount += itemInGroup.count;
        return itemInGroup;
      });
      groupLimitReached = group.maximum === modifierCount;
      group.items = group.items.map((itemInGroup: any) => {
        itemInGroup.disabled = group.maximum > 0 && groupLimitReached;
        return itemInGroup;
      });
    }
    group.minimumSelected = modifierCount >= group.minimum;
    this.updateItemWithModifierSelection(group);
  };

  updateItemWithModifierSelection = (group: any) => {
    let modifierItem = deepCopyFunction(this.state.modifierItem);
    modifierItem.groups = modifierItem.groups.map((modifierGroup: any) => {
      if (group.id === modifierGroup.id) {
        modifierGroup = group;
      }
      return modifierGroup;
    });
    this.setState({ modifierItem });
  };

  checkIfCartIsDisabled = (item: any) => {
    let canBeAddedToCart = item.groups.every((modifierGroup: any) => {
      return modifierGroup.minimumSelected;
    });
    return !canBeAddedToCart;
  }

  onModifierItemDecrement = (
    e: React.MouseEvent<HTMLButtonElement>,
    modifierGroup: any,
    item: any
  ) => {
    item.count = item.count - 1;
    this.onCheckBoxClick(modifierGroup, item);
  };

  onModifierItemIncrement = (
    e: React.MouseEvent<HTMLButtonElement>,
    modifierGroup: any,
    item: any
  ) => {
    item.count = item.count + 1;
    this.onCheckBoxClick(modifierGroup, item);
  };

  addModifiedItemToCart = (e: any) => {
    this.props.onModifierAdd(e, deepCopyFunction(this.state.modifierItem));
  };

  decrementModifiedItemCount = () => {
    let modifierItem = deepCopyFunction(this.state.modifierItem)
    modifierItem.count = modifierItem.count - 1;
    this.setState({ modifierItem });
  };

  incrementModifiedItemCount = () => {
    let modifierItem = deepCopyFunction(this.state.modifierItem)
    modifierItem.count = modifierItem.count + 1;
    this.setState({ modifierItem });
  };

  changeModifiedItemCount = (count: any) => {
    let modifierItem = deepCopyFunction(this.state.modifierItem)
    modifierItem.count = Number(count || 0);
    this.setState({ modifierItem });
  };

  appliedModifierCount(group: any) {
    return group.items.reduce((count: number, item: any) => {
      return count + item.count;
    }, 0);
  }

  modifierSpecifications(minimum: number, maximum: number) {
    let specification = "";
    if (maximum > 0) {
      if (minimum == maximum) {
        specification = <FormattedMessage
                          id="menu.item.choose_minimum"
                          defaultMessage="Please choose {minimumCount}"
                          values={{
                            minimumCount: minimum,
                          }}
                        />;
      } else if (minimum > 0) {
        specification = <FormattedMessage
                          id="menu.item.choose_minimum_and_maximum"
                          defaultMessage="Please choose minimum of {minimumCount} and maximum of {maximumCount}"
                          values={{
                            minimumCount: minimum,
                            maximumCount: maximum,
                          }}
                        />;
      } else {
        specification = <FormattedMessage
                          id="menu.item.choose_maximum"
                          defaultMessage="Please choose maximum of {maximumCount}"
                          values={{
                            maximumCount: maximum,
                          }}
                        />;
      }
    } else if (minimum > 0) {
      specification = <FormattedMessage
                        id="menu.item.choose_minimum"
                        defaultMessage="Please choose {minimumCount}"
                        values={{
                          minimumCount: minimum,
                        }}
                      />;
    }
    return specification;
  }

  render() {
    const { cartDisabled, modifierItem, show } = this.state;
    const { currency, intl } = this.props;

    return (
      <Modal
        show={show}
        dialogClassName="custom customisation-popup"
        onHide={this.handleClose}
        size="lg"
        centered
      >
        <div className="customisation">
          <div
            className="item-image"
            style={{
              backgroundImage: `url(${transformImageQuality(
                modifierItem.image_url,
                QUALITY_20
              )})`,
            }}
          />
          <div className="customisation-section">
            <div className="customisation-header">
              {translatedName(modifierItem, intl.locale)}
            </div>
            <div className="customisation-body">
              {modifierItem.groups
                ? modifierItem.groups.map((modifierGroup: any, index: number) => {
                  let specification = this.modifierSpecifications(
                    modifierGroup.minimum, modifierGroup.maximum
                  );
                  return (
                    <div className="modifier-group" key={index}>
                      <ModalCloseIcon onClick={this.handleClose} />
                      <div className="group-header">
                        <h1 className="name">
                          {translatedName(modifierGroup, intl.locale)}
                          {modifierGroup.minimum > 0 ? (
                            <span>*</span>
                          ) : null}
                          {specification ? (
                            <p className="limit-info">
                              {specification}
                            </p>
                          ) : null}
                        </h1>
                        {modifierGroup.maximum > 0 ? (
                          <div>
                            <FormattedMessage
                              id="menu.item.modifier_counter"
                              defaultMessage="{selectedCount} of {maximumCount}"
                              values={{
                                selectedCount: this.appliedModifierCount(modifierGroup),
                                maximumCount: modifierGroup.maximum
                              }}
                            />
                          </div>
                        ) : null}
                      </div>
                      <div className="group-items">
                        {modifierGroup.items.map((item: any, index: number) => {
                          return (
                            <div className="item" key={index}>
                              <label htmlFor={`${index}`} className="item-name">
                                <p className="name">
                                  {translatedName(item, intl.locale)}
                                </p>
                                {item.unit_price > 0 ? (
                                  <p className="price">
                                    +{currency} {formatDecimal(item.unit_price)}
                                  </p>
                                ) : null}
                              </label>
                              {modifierGroup.type ===
                                "multiple_item" && (
                                <button
                                  id={`${index}`}
                                  className={
                                    item.selected
                                      ? "check-box selected"
                                      : "check-box"
                                  }
                                  onClick={() =>
                                    this.onCheckBoxClick(modifierGroup, item)
                                  }
                                  disabled={item.disabled ? true : false}
                                />
                              )}
                              {modifierGroup.type === "quantity_selector" && (
                                <div className="count-controller">
                                  <button
                                    onClick={(
                                      e: React.MouseEvent<HTMLButtonElement>
                                    ) => this.onModifierItemDecrement(
                                      e, modifierGroup, item
                                    )}
                                    className="btn btn-primary btn-sm px-3 py-2 primary"
                                    disabled={item.count === 0 ? true : false}
                                  >
                                    <FontAwesomeIcon icon={faMinus} />
                                  </button>
                                  <input
                                    readOnly
                                    type="number"
                                    className="count-input py-3"
                                    value={item.count}
                                    onChange={() => {}}
                                  ></input>
                                  <button
                                    onClick={(
                                      e: React.MouseEvent<HTMLButtonElement>
                                    ) => this.onModifierItemIncrement(
                                      e, modifierGroup, item
                                    )}
                                    className="btn btn-primary btn-sm px-3 py-2 primary"
                                    disabled={item.disabled ? true : false}
                                  >
                                    <FontAwesomeIcon icon={faPlus} />
                                  </button>
                                </div>
                              )}
                              {modifierGroup.type === "single_item" && (
                                <button
                                  id={`${index}`}
                                  className={
                                    item.selected
                                      ? "radio-button selected"
                                      : "radio-button"
                                  }
                                  onClick={() =>
                                    this.onCheckBoxClick(modifierGroup, item)
                                  }
                                ></button>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )
                })
                : null
              }
            </div>
            <div className="customisation-footer">
              <div className="count-controller">
                <button
                  className="btn btn-primary btn-sm px-3 py-2 primary"
                  onClick={() => this.decrementModifiedItemCount()}
                  disabled={modifierItem.count <= 1}
                >
                  <FontAwesomeIcon icon={faMinus} />
                </button>
                <input
                  readOnly
                  type="number"
                  className="count-input py-3"
                  value={modifierItem.count || 1}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement>
                  ) => this.changeModifiedItemCount(e.target.value)}
                ></input>
                <button
                  className="btn btn-primary btn-sm px-3 py-2 primary"
                  onClick={() => this.incrementModifiedItemCount()}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
              <button
                className="button btn btn-primary py-2 w-100"
                onClick={(e: any) => this.addModifiedItemToCart(e)}
                disabled={cartDisabled}
              >
                <FormattedMessage
                  id="menu.item.add_to_order"
                  defaultMessage="Add to Order"
                />
                <span className="ml-3">
                  {currency} {formatDecimal(this.state.subTotal)}
                </span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: any) => {
  let currency = state.company.currency;

  return {
    currency,
  };
};

export default injectIntl(connect(mapStateToProps)(CustomisationPopup));
