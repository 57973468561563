// Packages
import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import "bootstrap/js/dist/dropdown.js";
import { FormattedMessage } from 'react-intl';
// Components
import SignIn from "../signIn";
import RestaurantCloseBanner from "../resCloseBanner";
import Loader from "../../components/loader";
import ErrorPopup from "../errorPopup";
import InlineLoader from "../inlineLoader";
import AreaSelectPopup from "../areaSelectPopup";
import OrdersDropdown from "../ordersDropdown";
import OnBoardingPopup from "../onBoardingPopup";
import LocaleDropdown from "../localeDropdown";
import AlertPopup from "../alertPopup";
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import Coin from "../../images/coin.png";
// Redux Operations
import { userOperations } from "../../state/features/user";
import { loyaltyOperations } from "../../state/features/loyalty";
import { sessionOperations } from "../../state/features/session";
// Helpers, Utils etc.
import trackingDetails from "../../helpers/orderTracking";
import { formatDecimal } from "../../helpers/itemCalculations";
import PaymentInProgressBanner from "../../components/paymentInProgressBanner";
import { promotionsOperations } from "../../state/features/promotions";
import PromotionsModal from "../promotionsModal";
import AppliedPromotionsModal from "../appliedPromotionsModal";
import { QUALITY_10, transformImageQuality } from "../../helpers/imageTransformation";

interface IHeaderProps {
  company: any;
  user: any;
  storeTimings: any;
  loader: any;
  order: any;
  error: any;
  orderType: string;
  userLoyaltyPoints: any;
  loyaltyEnabled: boolean;
  alert: any;
  setUser: any;
  session: any;
  setSession: any;
  fetchLoyalty: any;
  fetchOrderInProgress: any;
  signOut: any;
  recentOrder: any;
  availablePromotions: any[];
  togglePromotionsModal(): void;
}

const Header = (props: IHeaderProps) => {
  const [showSignInModal, handleSignInModal] = useState(false);
  const [loginStatus, setLoginStatus] = useState(false);
  const [userNameOrMobileNumber, setUserNameOrMobileNumber] = useState("");
  const [showLogout, setLogoutVisibility] = useState(false);
  const [loyaltyLoading, setLoyaltyLoading] = useState(true);
  const [modesOfOrdering, setModeOfOrdering] = useState("delivery");
  const [tempOrderType, setTemporderType] = useState("delivery");
  const [areaName, setAreaName] = useState("" as any);
  const [showAreaHover, setShowAreaHover] = useState(false);
  const [showOrderTypes, setShowOrderTypes] = useState(false);

  const onSignInClick = () => handleSignInModal(true);

  const location = useLocation();

  useEffect(() => {
    if (props.company.brand_color) {
      let tint = checkTint(props.company.brand_color);
      let textColor = "#ffffff";
      let svgFilter = "brightness(0) invert(1)";
      let schemeColor = "dark";
      if (tint >= 170) {
        textColor = "#414952";
        svgFilter = "brightness(0) invert(0)";
        schemeColor = "light";
      }

      document.documentElement.style.setProperty("--text-color", textColor);

      document.documentElement.style.setProperty(
        "--primary-color",
        props.company.brand_color
      );

      //iOS 15 update: Add theme color
      document.querySelector('meta[name="theme-color"]').setAttribute("content", props.company.brand_color);
      document.querySelector('meta[name="theme-color"]').setAttribute("media", `(prefers-color-scheme: ${schemeColor})`);

      document.documentElement.style.setProperty("--svg-filter", svgFilter);
    }
    if (props.company.modes_of_delivery) {
      setModeOfOrdering(props.company.modes_of_delivery);
      if (props.company.modes_of_delivery != "delivery_and_pickup") {
        setTemporderType(props.company.modes_of_delivery);
      }
      if (
        props.session.mandatory &&
        props.company.modes_of_delivery == "delivery_and_pickup"
      ) {
        setShowOrderTypes(true);
      }
    }
  }, [props.company]);

  useEffect(() => {
    if (props.session.area_name) {
      let area_name = props.session.area_name;
      if (area_name && area_name.length > 10) {
        setShowAreaHover(true);
      } else {
        setShowAreaHover(false);
      }
      setAreaName(area_name);
    }
  }, [props.session.area_name]);

  const checkTint = (hex_color: any) => {
    let rgb: any = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex_color),
      r = parseInt(rgb[1], 16),
      g = parseInt(rgb[2], 16),
      b = parseInt(rgb[3], 16);

    r = Math.round(r * 0.299);
    g = Math.round(g * 0.587);
    b = Math.round(b * 0.114);

    var checker = r + g + b;
    return checker;
  };

  useEffect(() => {
    if (props.user.id && props.user.phone_number && props.user.dialing_code) {
      setLoginStatus(true);
      if (props.user.name) {
        setUserNameOrMobileNumber(props.user.name);
      } else {
        setUserNameOrMobileNumber(
          "+" + props.user.dialing_code + props.user.phone_number
        );
      }
      if (props.user.proceed_to_checkout) {
        proceedToCheckout(props.user);
      }
    } else {
      setLoginStatus(false);
      setUserNameOrMobileNumber("");
      if (props.user.proceed_to_checkout) {
        handleSignInModal(true);
      }
    }
  }, [props.user]);

  useEffect(() => {
    if (props.user.id) {
      if (
        ["/", "/my-account/profile", "/my-account/past-orders"].includes(
          location.pathname
        ) &&
        props.loyaltyEnabled
      ) {
        props.fetchLoyalty();
      }
      setLoyaltyLoading(false);
      setTimeout(() => {
        if (!props.order || !props.order.order_number) {
          props.fetchOrderInProgress();
        }
      }, 500);
    }
  }, [props.user.id]);

  useEffect(() => {
    if (props.company.id) {
      updateLogoSize();
    }
  }, [props.company.id]);

  const proceedToCheckout = (user: any) => {
    user.proceed_to_checkout = false;
    props.setUser(user);
    document.getElementById("goToCheckoutFromCartBtn")?.click();
  };

  const onLogoutClick = () => {
    props.signOut().then((response: any) => {
      if (!response.error) {
        window.location.href = response.payload.data.redirect_url;
      }
    });
  };

  const onUserActionComplete = (user: any) => {
    if (props.user.proceed_to_checkout) {
      user.proceed_to_checkout = props.user.proceed_to_checkout;
    }
    props.setUser(user);
  };

  const runningOrder = () => {
    const order_type =
      props.order.order_type || props.order.order_details.order_type;
    return (
      <React.Fragment>
        <div
          className="current-order-status"
          onClick={() => goTo("/trackorder")}
        >
          <p className="order-status">
            <FormattedMessage
              id={`order.${order_type}.${props.order.status}.tracking_title`}
              defaultMessage={`Order is ${trackingDetails[props.order.status][order_type].title.toLowerCase()}`}
            />
          </p>
          <p className="order-number">
            <FormattedMessage
              id="order.order_number_with_hash"
              defaultMessage="Order# {orderNumber}"
              values={{
                orderNumber: props.order.order_number
              }}
            />
          </p>
          <p className="ordered-date">
            <FormattedMessage
              id="order.ordered_at"
              defaultMessage="Ordered {orderDate}"
              values={{
                orderDate: dateFromString(props.order.created_at)
              }}
            />
          </p>
        </div>
      </React.Fragment>
    );
  };

  const dateFromString = (dateString: string) => {
    let date = new Date(dateString);

    return date.toLocaleDateString(undefined, {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const goTo = (url: string) => {
    window.location.href = url;
  };

  const openAreaSelect = (type: string) => {
    setTemporderType(type);
    let session = props.session as any;
    session.showAreaSelect = true;
    session.mandatory = false;
    props.setSession(session);
  };

  const hideAreaSelect = () => {
    let session = props.session as any;
    session.showAreaSelect = false;
    props.setSession(session);
  };

  const handleTempOrderType = (type: string) => {
    setTemporderType(type);
  };

  const selectOrderType = (type: string) => {
    setTemporderType(type);
    setShowOrderTypes(false);
  };

  const updateLogoSize = () => {
    var logoSizeUpdater;
    clearTimeout(logoSizeUpdater);
    logoSizeUpdater = setTimeout(() => {
      var textLogoWidth = window.document.getElementById("company-name-temp")
        ?.offsetWidth;
      var svgLogo = window.document.getElementById("svglogo");

      svgLogo?.setAttribute("viewBox", "0 0 " + textLogoWidth + " 80");
    }, 1000);
  };

  const orderTypeSelector = () => {
    return (
      <React.Fragment>
        {modesOfOrdering == "delivery_and_pickup" ? (
          <div className="dropdown">
            <div
              className="select-area-option mx-4 d-flex align-items-center"
              id="dropdownMenuButton"
              data-toggle={
                modesOfOrdering == "delivery_and_pickup" ? "dropdown" : false
              }
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div>
                <p className="order-type">
                  {props.session.order_type === "pickup" ? (
                    <FormattedMessage
                      id="header.pickup_from"
                      defaultMessage="Pickup from"
                    />
                  ) : (
                    <FormattedMessage
                      id="header.delivering_to"
                      defaultMessage="Delivering to"
                    />
                  )}
                </p>
                <div className="area-name">
                  {areaName}
                  {showAreaHover ? (
                    <p className="area-name-on-hover">{areaName}</p>
                  ) : null}
                </div>
              </div>
              <FontAwesomeIcon className="ml-2" icon={faCaretDown} />
            </div>
            <div
              className="dropdown-menu categories-dropdown py-2"
              aria-labelledby="dropdownMenuButton"
            >
              <div
                onClick={() => openAreaSelect("delivery")}
                className="category-item"
              >
                <FormattedMessage
                  id="header.for_delivery"
                  defaultMessage="For Delivery"
                />
              </div>
              <div
                onClick={() => openAreaSelect("pickup")}
                className="category-item"
              >
                <FormattedMessage
                  id="header.for_pickup"
                  defaultMessage="For Pickup"
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div
              className="select-area-option dropdown-container"
              onClick={() => openAreaSelect(modesOfOrdering)}
            >
              <div>
                <p className="order-type">
                  {modesOfOrdering === "pickup" ? (
                    <FormattedMessage
                      id="header.for_pickup"
                      defaultMessage="For Pickup"
                    />
                  ) : (
                    <FormattedMessage
                      id="header.for_delivery"
                      defaultMessage="For Delivery"
                    />
                  )}
                </p>
                <p className="area-name">
                  {areaName}
                  {showAreaHover ? (
                    <p className="area-name-on-hover">{areaName}</p>
                  ) : null}
                </p>
              </div>
              <FontAwesomeIcon className="ml-1" icon={faCaretDown} />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  const togglePromotionModal = () => {
    setLogoutVisibility(false);
    props.togglePromotionsModal();
  }

  return (
    <div className="sticky-top" id="top-bar">
      {props.recentOrder && location.pathname == '/' ? <PaymentInProgressBanner /> : null}
      {props.loader.loading && <Loader message={props.loader.message} />}
      {props.storeTimings.open == false && (
        <RestaurantCloseBanner storeTimings={props.storeTimings} />
      )}
      <nav id="top-nav" className="navbar navbar-expand-lg topHeader">
        <Link to="/" className="navbar-brand headerBrand">
          <img src={transformImageQuality(props.company.logo, QUALITY_10)} />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
          <ul className="navbar-nav mt-2 navbar-right-options">
            {props.company.locale_data?.locales?.length > 1 ? (
              <li className="nav-item d-flex mr-4 align-items-center option option-lang dropdown-container">
                <LocaleDropdown />
              </li>
            ) : false}

            {location.pathname === "/" ? (
              <li className="nav-item d-flex mr-4 align-items-center option option-lang dropdown-container">
              <React.Fragment>
                {orderTypeSelector()}
                {props.session.showAreaSelect ? (
                  showOrderTypes ? (
                    <OnBoardingPopup selectOrderType={selectOrderType} />
                  ) : (
                    <AreaSelectPopup
                      onClose={() => hideAreaSelect()}
                      mandatory={props.session.mandatory}
                      orderType={tempOrderType}
                      handleTempOrderType={handleTempOrderType}
                      showOrderTypes={() => setShowOrderTypes(true)}
                    />
                  )
                ) : null}
              </React.Fragment>
              </li>
            ) : null}
            <li className="nav-item d-flex mr-0 align-items-center option option-lang dropdown-container">
              <Dropdown
                drop={"down"}
                show={showLogout}
                onToggle={
                  loginStatus
                    ? () => setLogoutVisibility(!showLogout)
                    : onSignInClick
                }
              >
                <Dropdown.Toggle
                  as="div"
                  className="d-flex align-items-center"
                  id={"login-button"}
                >
                  <FontAwesomeIcon size="1x" icon={faUser} />
                  <p className="mx-2">
                    {loginStatus ? (
                      userNameOrMobileNumber
                    ) : (
                      <FormattedMessage
                        id="registration.sign_in"
                        defaultMessage="Sign In"
                      />
                    )}
                  </p>
                </Dropdown.Toggle>
                {showLogout ? (
                  <Dropdown.Menu className="account-dropdown">
                    <div>
                      <div className="arrow"></div>
                      {props.order && runningOrder()}
                      {props.loyaltyEnabled ? (
                        loyaltyLoading ? (
                          <InlineLoader />
                        ) : (
                          <div
                            className="loyalty-info"
                            style={{ backgroundImage: `url(${Coin})` }}
                            onClick={() => goTo("/my-account/loyalty")}
                          >
                            <div className="loyalty">
                              <FormattedMessage
                                id="sidemenu.loyalty.you_currently_have_loyalty_points"
                                defaultMessage="<labelTag>You currently have</labelTag><pointsTag>{userLoyalty} Loyalty {currency} </pointsTag>"
                                values={{
                                  labelTag: (text) => <p className="label">{text}</p>,
                                  pointsTag: (text) => <p className="points">{text}</p>,
                                  userLoyalty: formatDecimal(props.userLoyaltyPoints),
                                  currency: props.company.currency
                                }}
                              />
                            </div>
                            <button className="details">
                              <FormattedMessage
                                id="global.uppercase.details"
                                defaultMessage="DETAILS"
                              />
                            </button>
                          </div>
                        )
                      ) : null}
                      <div
                        className="dropdown-option"
                        onClick={() => goTo("/my-account/profile")}
                      >
                        <FormattedMessage
                          id="sidemenu.my_account"
                          defaultMessage="My Account"
                        />
                      </div>
                      {props.availablePromotions.length ? (
                        <div
                          className="dropdown-option"
                          onClick={togglePromotionModal}
                        >
                          <FormattedMessage
                            id="sidemenu.promotions"
                            defaultMessage="Promotions"
                          />
                        </div>
                      ) : null}
                      <div
                        className="dropdown-option"
                        onClick={() => goTo("/my-account/past-orders")}
                      >
                        <FormattedMessage
                          id="sidemenu.my_orders"
                          defaultMessage="My Orders"
                        />
                      </div>
                      <div className="dropdown-option" onClick={onLogoutClick}>
                        <FormattedMessage
                          id="sidemenu.logout"
                          defaultMessage="Logout"
                        />
                      </div>
                    </div>
                  </Dropdown.Menu>
                ) : null}
              </Dropdown>
            </li>
          </ul>
        </div>

        {showSignInModal ? (
          <SignIn onClose={() => handleSignInModal(false)} />
        ) : null}
      </nav>
      <PromotionsModal/>
      <AppliedPromotionsModal />
      {props.error && <ErrorPopup />}
      {props.alert && <AlertPopup />}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  let company = state.company;
  let user = state.user || {};
  let storeTimings = state.storeTimings;
  let loader = state.loader;
  let order = state.orderInProgress;
  let error = state.error;
  let orderType = state.session.order_type;
  let userLoyaltyPoints = state.loyalty.customer_loyalty || 0;
  let session = state.session;
  let loyaltyEnabled = state.loyaltyConfig[orderType]?.enabled;
  let alert = state.alert;
  let recentOrder = state.recentOrder;
  let availablePromotions = state.promotions.availablePromotions

  return {
    company,
    user,
    storeTimings,
    loader,
    order,
    error,
    orderType,
    userLoyaltyPoints,
    session,
    loyaltyEnabled,
    alert,
    recentOrder,
    availablePromotions,
  };
};

const mapDispatchToProps = {
  setUser: userOperations.setUser,
  fetchLoyalty: loyaltyOperations.fetchLoyalty,
  setSession: sessionOperations.setSession,
  fetchOrderInProgress: userOperations.fetchOrderInProgress,
  signOut: userOperations.signOut,
  togglePromotionsModal: promotionsOperations.toggleShowPromotionModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
