import * as types from "./types";
import * as pageTypes from "../page/types";
import * as userTypes from "../user/types";
import * as orderTypes from "../orderInProgress/types";
import * as sessionTypes from "../session/types";
import * as areaTypes from "../areas/types";
import * as addressTypes from "../addresses/types";
import * as errorTypes from "../error/types";
import * as paymentTypeTypes from "../paymentTypes/types";
import * as savedCardsTypes from "../savedCards/types";
import * as invoiceTypes from "../invoice/types";
import * as tipTypes from "../tips/types";

const deliveryAreaChangeLoaderStatus = (action) => {
  if (action.payload.data.outlet_changed) {
    return { loading: true };
  } else {
    return { loading: false };
  }
}

const redirectionLoaderStatus = (action) => {
  if (action.payload.data.payment_page_url) {
    return { loading: true };
  } else {
    return { loading: false };
  }
}

const loaderReducer = (state = { loading: false }, action: any) => {
  switch (action.type) {
    case pageTypes.FETCH_HOME_CONFIGURATIONS:
    case pageTypes.FETCH_CHECKOUT_CONFIGURATIONS:
    case pageTypes.FETCH_TRACKING_CONFIGURATIONS:
    case pageTypes.FETCH_PROFILE_CONFIGURATIONS:
    case pageTypes.FETCH_PAST_ORDERS_CONFIGURATIONS:
    case pageTypes.FETCH_LOYALTY_CONFIGURATIONS:
    case pageTypes.FETCH_SIGN_IN_CONFIGURATIONS:
    case pageTypes.FETCH_ORDER_DETAILS_CONFIGURATIONS:
    case pageTypes.FETCH_RIDER_TRACKING_CONFIGURATIONS:
    case orderTypes.PLACE_ORDER:
    case userTypes.UPDATE_PROFILE:
    case userTypes.SIGN_OUT:
    case userTypes.VERIFY_USER:
    case userTypes.SIGN_UP:
    case userTypes.SIGN_IN:
    case userTypes.SEND_OTP:
    case userTypes.RESEND_OTP:
    case userTypes.SEND_OTP_FOR_EMAIL_RESET:
    case userTypes.RESEND_OTP_FOR_EMAIL_RESET:
    case sessionTypes.CHANGE_DELIVERY_AREA:
    case areaTypes.FETCH_AREAS:
    case addressTypes.UPDATE_ADDRESS:
    case addressTypes.DELETE_ADDRESS:
    case addressTypes.CREATE_ADDRESS:
    case types.SHOW_LOADER:
    case paymentTypeTypes.BUILD_QPAY_PAYMENT_DATA:
    case paymentTypeTypes.BUILD_MASTERCARD_SESSION:  
    case savedCardsTypes.DELETE_SAVED_CARD:
    case orderTypes.MARK_AS_CUSTOMER_ARRIVED:
    case invoiceTypes.SEND_INVOICE_TO_REGISTERED_EMAIL:
    case invoiceTypes.SEND_INVOICE_TO_NEW_EMAIL:
    case tipTypes.MAKE_TIP_PAYMENT:
    case paymentTypeTypes.INITIATE_STRIPE_CONNECT_TRANSACTION:
      return { loading: true };
    case pageTypes.FETCH_HOME_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_CHECKOUT_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_TRACKING_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_PROFILE_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_PAST_ORDERS_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_LOYALTY_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_SIGN_IN_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_ORDER_DETAILS_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_RIDER_TRACKING_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_HOME_CONFIGURATIONS_FAIL:
    case pageTypes.FETCH_CHECKOUT_CONFIGURATIONS_FAIL:
    case pageTypes.FETCH_TRACKING_CONFIGURATIONS_FAIL:
    case pageTypes.FETCH_PROFILE_CONFIGURATIONS_FAIL:
    case pageTypes.FETCH_PAST_ORDERS_CONFIGURATIONS_FAIL:
    case pageTypes.FETCH_LOYALTY_CONFIGURATIONS_FAIL:
    case pageTypes.FETCH_SIGN_IN_CONFIGURATIONS_FAIL:
    case pageTypes.FETCH_ORDER_DETAILS_CONFIGURATIONS_FAIL:
    case pageTypes.FETCH_RIDER_TRACKING_CONFIGURATIONS_FAIL:
    case orderTypes.PLACE_ORDER_FAIL:
    case userTypes.UPDATE_PROFILE_SUCCESS:
    case userTypes.UPDATE_PROFILE_FAIL:
    case userTypes.SIGN_OUT_FAIL:
    case userTypes.VERIFY_USER_SUCCESS:
    case userTypes.VERIFY_USER_FAIL:
    case userTypes.SIGN_UP_SUCCESS:
    case userTypes.SIGN_UP_FAIL:
    case userTypes.SIGN_IN_SUCCESS:
    case userTypes.SIGN_IN_FAIL:
    case userTypes.SEND_OTP_SUCCESS:
    case userTypes.SEND_OTP_FAIL:
    case userTypes.RESEND_OTP_SUCCESS:
    case userTypes.RESEND_OTP_FAIL:
    case userTypes.SEND_OTP_FOR_EMAIL_RESET_SUCCESS:
    case userTypes.SEND_OTP_FOR_EMAIL_RESET_FAIL:
    case userTypes.RESEND_OTP_FOR_EMAIL_RESET_SUCCESS:
    case userTypes.RESEND_OTP_FOR_EMAIL_RESET_FAIL:
    case sessionTypes.CHANGE_DELIVERY_AREA_FAIL:
    case areaTypes.FETCH_AREAS_SUCCESS:
    case areaTypes.FETCH_AREAS_FAIL:
    case addressTypes.UPDATE_ADDRESS_SUCCESS:
    case addressTypes.UPDATE_ADDRESS_FAIL:
    case addressTypes.DELETE_ADDRESS_SUCCESS:
    case addressTypes.DELETE_ADDRESS_FAIL:
    case addressTypes.CREATE_ADDRESS_SUCCESS:
    case addressTypes.CREATE_ADDRESS_FAIL:
    case errorTypes.RESET_ERROR:
    case paymentTypeTypes.BUILD_QPAY_PAYMENT_DATA_FAIL:
    case paymentTypeTypes.BUILD_MASTERCARD_SESSION_FAIL:
    case savedCardsTypes.DELETE_SAVED_CARD_SUCCESS:
    case savedCardsTypes.DELETE_SAVED_CARD_FAIL:
    case orderTypes.MARK_AS_CUSTOMER_ARRIVED_SUCCESS:
    case orderTypes.MARK_AS_CUSTOMER_ARRIVED_FAIL:
    case invoiceTypes.SEND_INVOICE_TO_REGISTERED_EMAIL_SUCCESS:
    case invoiceTypes.SEND_INVOICE_TO_REGISTERED_EMAIL_FAIL:
    case invoiceTypes.SEND_INVOICE_TO_NEW_EMAIL_SUCCESS:
    case invoiceTypes.SEND_INVOICE_TO_NEW_EMAIL_FAIL:
    case tipTypes.MAKE_TIP_PAYMENT_FAIL:
    case paymentTypeTypes.INITIATE_STRIPE_CONNECT_TRANSACTION_FAIL:
    case types.HIDE_LOADER:
      return { loading: false };
    case sessionTypes.CHANGE_DELIVERY_AREA_SUCCESS:
      return deliveryAreaChangeLoaderStatus(action);
    case types.UPDATE_LOADER:
      return action.payload;
    case types.RESET_LOADER:
      return null;
    case orderTypes.PLACE_ORDER_SUCCESS:
    case tipTypes.MAKE_TIP_PAYMENT_SUCCESS:
      return redirectionLoaderStatus(action);
    default: return state;
  }
};

const reducer = loaderReducer;

export default reducer;
